<template>
	<b-col cols="12" class="mb-1">
		<div class="d=flex align-items-center flex-wrap justify-content-end text-right">
			<!-- <b-button
				@click="addNursery.show = !addNursery.show"
				variant="gradient-secondary"
				class="position-relative mr-1"
			>
				<FeatherIcon icon="HeartIcon" class="cursor-pointer" size="18" />
				ENVIAR A ENFERMERIA
			</b-button> -->
			<!-- <b-button
				@click="clinicalActions.show = !clinicalActions.show"
				variant="gradient-secondary"
				class="position-relative mr-1"
			>
				<FeatherIcon icon="ActivityIcon" class="cursor-pointer" size="18" />
				Acciones clinicas
			</b-button> -->
			<!-- <b-button @click="modalToSale.show = true" variant="gradient-warning" class="position-relative mr-1">
				<div class="d-flex align-items-center justify-content-center">
					<FeatherIcon icon="GlobeIcon" class="cursor-pointer mr-25" size="18" />
					Web
					<b-badge
						v-if="counterWeb > 0"
						variant="danger"
						style="top: -8px; right: -8px"
						class="position-absolute"
					>
						{{ counterWeb }}
					</b-badge>
				</div>
			</b-button> -->

			<!-- <b-button
				variant="primary"
				class="position-relative mr-2"
				@click="openModalEmplacados"
				v-if="isAdmin || isPreparador"
			>
				<feather-icon icon="GridIcon" class="" />
				Nuevos
				<b-badge
					v-if="counterPlatesPending > 0"
					variant="danger"
					style="top: -8px; right: -8px"
					class="position-absolute"
				>
					{{ counterPlatesPending }}
				</b-badge>
			</b-button> -->

			<!-- <b-button :to="{ name: 'inventory-specimens-list-sr' }" variant="gradient-success" class="mr-1">
				<div class="d-flex align-items-center justify-content-center">
					<FeatherIcon icon="ArchiveIcon" class="cursor-pointer mr-25" size="18" />
					Inventario
				</div>
			</b-button> -->
			<b-dropdown id="dropdown-right" right text="Agregar" variant="primary" class="mr-1">
				<b-dropdown-item :to="{ name: 'create-specimen' }">Simple</b-dropdown-item>
				<b-dropdown-item :to="{ name: 'create-many-specimens' }">Multiple</b-dropdown-item>
			</b-dropdown>

			<!-- <b-button variant="gradient-info" class="mr-1" @click="sidebarMassivelyChanges = true">
				MÁS ACCIONES
			</b-button> -->

			<b-dropdown right variant="success" text="Más acciones">
				<b-dropdown-item @click="addNursery.show = !addNursery.show">
					<div class="d-flex align-items-center justify-content-start gap-x-1">
						<FeatherIcon icon="HeartIcon" size="18" />
						<span>Enviar a enfermeria</span>
					</div>
				</b-dropdown-item>

				<b-dropdown-item @click="clinicalActions.show = !clinicalActions.show">
					<div class="d-flex align-items-center justify-content-start gap-x-1">
						<FeatherIcon icon="ActivityIcon" size="18" />
						<span>Acciones clinicas</span>
					</div>
				</b-dropdown-item>

				<b-dropdown-item @click="modalToSale.show = true">
					<div
						class="position-relative d-flex align-items-center justify-content-start gap-x-1"
						style="width: fit-content"
					>
						<FeatherIcon icon="GlobeIcon" size="18" />
						<span>Web</span>
						<b-badge v-if="counterWeb > 0" variant="danger" style="border-radius: 50%">
							{{ counterWeb }}
						</b-badge>
					</div>
				</b-dropdown-item>

				<b-dropdown-item @click="openModalEmplacados" v-if="isAdmin || isPreparador">
					<div class="d-flex align-items-center justify-content-start gap-x-1">
						<feather-icon icon="GridIcon" size="18" />

						<span>Nuevos</span>

						<b-badge v-if="counterPlatesPending > 0" variant="danger" style="border-radius: 50%">
							{{ counterPlatesPending }}
						</b-badge>
					</div>
				</b-dropdown-item>

				<b-dropdown-item :to="{ name: 'inventory-specimens-list-sr' }">
					<div class="d-flex align-items-center justify-content-start gap-x-1">
						<FeatherIcon icon="ArchiveIcon" size="18" />
						<span>Inventario</span>
					</div>
				</b-dropdown-item>
				
				<!-- <b-dropdown-item @click="sidebarMassivelyChanges = true">
					<div class="d-flex align-items-center justify-content-start gap-x-1">
						<feather-icon icon="PlusIcon" size="18" />
						<span>Acciones masivas</span>
					</div>
				</b-dropdown-item> -->
			</b-dropdown>
		</div>

		<add-specimen-weight
			v-if="specimenWeightController"
			@hidden="specimenWeightController = false"
			@update-specimen-weight="updateSpecimenWeight"
			:specimen-obj="selectedSpecimen"
		></add-specimen-weight>

		<specimen-weight-tracking-modal
			v-if="specimenWeightListController"
			@hidden="specimenWeightListController = false"
			:specimen-obj="selectedSpecimen"
		></specimen-weight-tracking-modal>

		<SpecimenStatusChangedModal
			v-if="specimenStatusController"
			@hidden="specimenStatusController = false"
			:specimen-obj="selectedSpecimen"
			:array-status="arrayStatus"
			@update-status="resetSearch"
		></SpecimenStatusChangedModal>

		<b-modal
			v-if="modalViewVideosBySpecimen"
			hide-footer
			v-model="modalViewVideosBySpecimen"
			:title="`Video del ejemplar ${
				modalViewVideosBySpecimenItem.alias ? `${modalViewVideosBySpecimenItem.alias} - ` : ''
			} (${modalViewVideosBySpecimenItem.plate ? modalViewVideosBySpecimenItem.plate : ''})`"
			size="xl"
			lazy
		>
			<ContainerSpecimenListVideos :id_specimen="modalViewVideosBySpecimenItem.id" />
		</b-modal>

		<SidebarMassivelyChanges v-if="sidebarMassivelyChanges" @hidden="closeSidebarMassivelyChanges" />

		<SidebarDeads
			v-if="controllerSideBarDeads"
			@hidden="controllerSideBarDeads = false"
			:category_id="categoryId"
		/>

		<b-modal
			v-model="modalTotalPosturas.show"
			:title="modalTotalPosturas.title"
			size="xl"
			hide-footer
			scrollable
			@hidden="modalTotalPosturas.show = false"
			body-class="p-2 m-0"
		>
			<!-- <PosturasByIdSpecimen :modalTotalPosturas="modalTotalPosturas" :year="year" /> -->
			<!-- <PostutrasListByLote :specimen="{ category: categoryId, id: currentSpecimen.id }"/> -->
			<ViewPostureStatusBySpecimenId
				v-if="modalTotalPosturas.show"
				:isOnlySpecimen="true"
				:specimenId="currentSpecimen.id"
				:categoryId="categoryId"
			/>
			<!-- isOnlySpecimen: [Boolean],
		specimenId: [Number, String],
		categoryId: [Number, String], -->
		</b-modal>

		<b-modal
			v-model="modalTotalTournaments.show"
			:title="`Total de torneos del ejemplar ${modalTotalTournaments.title}`"
			size="xl"
			hide-footer
			scrollable
			@hidden="modalTotalTournaments.show = false"
		>
			<ReportTournamentsComponentVue :resultsNow="true" :plate="modalTotalTournaments.plate" />
		</b-modal>

		<!-- hijos vivos del ejemplar -->
		<b-modal
			v-model="modalSonsBySpecimen.show"
			:title="modalSonsBySpecimen.title"
			size="lg"
			hide-footer
			@hidden="modalSonsBySpecimen.show = false"
			content-class="m-0 p-0"
		>
			<SonsByIdSpecimen :modalSonsBySpecimen="modalSonsBySpecimen" :category="categoryId" :year="year" />
		</b-modal>

		<SidebarChicksGlobal
			v-if="controllerSidebarChicksGlobal"
			@hidden="controllerSidebarChicksGlobal = false"
			:specimen="specimenGlobal"
			:yearSelected="year"
		/>

		<b-modal v-if="modalAddDob.show" hide-footer v-model="modalAddDob.show" :title="modalAddDob.title">
			<b-form-group label="Nacimiento">
				<validation-observer ref="form">
					<ValidationProvider rules="required" v-slot="{ errors }" class="w-100">
						<flat-pickr
							v-model="modalAddDob.dob"
							class="form-control bg-white"
							placeholder="Seleccionar fechas"
							:class="[errors[0] ? 'border-danger' : '']"
						/>
						<small v-if="errors[0]" class="text-danger">{{ errors[0] }}</small>
					</ValidationProvider>
				</validation-observer>
			</b-form-group>
			<div class="d-flex justify-content-end">
				<button @click="saveDobSpecimen" class="btn btn-primary text-danger">Guardar</button>
			</div>
		</b-modal>

		<b-modal
			size="xl"
			hide-footer
			v-model="modalToSale.show"
			title="Ejemplares en web"
			no-close-on-backdrop
			@hidden="$refs['refUserListTable'].refresh(), webCounter()"
		>
			<SpecimensToWeb
				:counterPublicAndSale="counterPublicAndSale"
				@refresh="onChangeFilter"
				v-if="modalToSale.show"
			/>
		</b-modal>

		<ModalProcessChickPlates
			:show="showModalProcessChickPlates"
			@closing="
				showModalProcessChickPlates = false
				emplacedCounter()
			"
		/>
		<ClinicalActionsModal :show="clinicalActions.show" @closing="clinicalActions.show = false" />
		<AddSpecimensNursery :show="addNursery.show" @closing="addNursery.show = false" @refresh="refreshMainGrid" />
	</b-col>
</template>

<script>
import { bus } from "@/main"
import vSelect from "vue-select"
import store from "@/store"
import { ref, onUnmounted } from "@vue/composition-api"
import { avatarText } from "@core/utils/filter"
import useUsersList from "../users/useUsersList"
import userStoreModule from "../users/userStoreModule"
import axios from "@/axios"
import Ripple from "vue-ripple-directive"
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue"
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import SpecimenWeightTrackingModal from "./specimens-weight/SpecimenWeightTrackingModal.vue"
import SpecimenStatusChangedModal from "./components/SpecimenStatusChangedModal.vue"
import specimenService from "@/services/specimens.service"
import CorralService from "@/services/encaste/corral.service"
import publicWebService from "@/services/publicWeb.service"
import { mapState, mapMutations } from "vuex"
import VideoSpecimenListVue from "../videolibrary/VideoSpecimenList.vue"
import SidebarMassivelyChanges from "./components/SidebarMassivelyChanges.vue"
import SidebarDeads from "./components/SidebarDeads.vue"
import SidebarChicksGlobal from "./components/SidebarChicksGlobal.vue"
import PosturasByIdSpecimen from "./PosturasByIdSpecimen.vue"
import ReportTournamentsComponentVue from "../reports/results/tournaments/ReportTournamentsComponent.vue"
import SonsByIdSpecimen from "@/components/specimens/SonsByIdSpecimen.vue"
import ContainerSpecimenListVideos from "./dashboard/ContainerSpecimenListVideos.vue"
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"
import SpecimenAge from "@/components/specimens/SpecimenAge.vue"
import PostutrasListByLote from "@/views/amg/encaste/lotes/modals/PosturasListByLote.vue"
import ViewPostureStatusBySpecimenId from "../encaste/posturas/ViewPostureStatusBySpecimenId.vue"
import SpecimensToSale from "./SpecimensToSale.vue"
import ChangeSpecimenToPublic from "./ChangeSpecimenToPublic.vue"
import ChangeSpecimenToSale from "./ChangeSpecimenToSale.vue"
import SpecimensToWeb from "./SpecimensToWeb.vue"
import ModalProcessChickPlates from "@/views/amg/encaste/chickens/modals/ProcessChickPlates.vue"
import ClinicalActionsModal from "@/views/amg/specimens/components/ClinicalActionsModal.vue"
import AddSpecimensNursery from "@/views/amg/nursery/components/AddSpecimensNursery.vue"

export default {
	name: "BtnsSpecimensPage",
	directives: {
		Ripple,
	},
	components: {
		vSelect,
		AppCollapse,
		AppCollapseItem,
		SpecimenWeightTrackingModal,
		SpecimenStatusChangedModal,
		VideoSpecimenListVue,
		SidebarMassivelyChanges,
		SidebarDeads,
		PosturasByIdSpecimen,
		ReportTournamentsComponentVue,
		SonsByIdSpecimen,
		ContainerSpecimenListVideos,
		SpecimenPlate,
		SpecimenAge,
		SidebarChicksGlobal,
		PostutrasListByLote,
		ViewPostureStatusBySpecimenId,
		SpecimensToSale,
		ChangeSpecimenToPublic,
		ChangeSpecimenToSale,
		SpecimensToWeb,
		ModalProcessChickPlates,
		ClinicalActionsModal,
		AddSpecimensNursery,
	},
	props: {
		categoryId: {
			type: Number,
			default: null,
		},
		isFromSpecimenPage: Boolean,
		globalSearch: {
			type: String,
			default: "",
		},
	},
	computed: {
		visibleFields() {
			return this.arrayColumns.filter((field) => field.visible)
		},
		...mapState("header_book", ["isUpdatingFeature"]),
		sumSons: () => (item, key) => {
			return item.reduce(function (previousValue, currentValue) {
				return previousValue + parseInt(currentValue[key] * 1) || 0
			}, 0)
		},
		sumHasIsNumber: () => (cintillo_patas, sons_machos, sons_hembras, sons_sa) => {
			return (
				Number(cintillo_patas || 0 * 1) +
				Number(sons_machos || 0 * 1) +
				Number(sons_hembras || 0 * 1) +
				Number(sons_sa || 0 * 1)
			)
		},
		years() {
			this.year = this.seasons[0].id
			return this.$store.state.utilities.seasons
		},
		ammendedYear() {
			return this.seasonFromId(this.year)
		},
	},
	watch: {
		isUpdatingFeature() {
			// this.$refs.refUserListTable.refresh();
			this.images = []
			this.$store.commit("header_book/TOGGLE_IS_UPDATING_FEATURE", false)
		},
		year() {
			// this.$refs.refUserListTable.refresh();
		},
	},
	data() {
		return {
			sidebarMassivelyChanges: false,
			sortBy: "status",
			sortDesc: false,
			//Modals
			specimenWeightController: false,
			specimenWeightListController: false,
			specimenStatusController: false,
			arrayColumns: [
				{
					key: "specimen",
					label: "Ejemplar",
					class: "text-center",
					visible: true,
				},
				{
					key: "video",
					label: "Videos",
					class: "text-center ",
					visible: true,
					visible: [1, 3, 5].includes(this.categoryId) ? true : false,
				},
				// {
				//   key: "cs_description",
				//   label: "Categoría",
				//   class: "text-center ",
				//   //sortable: true,
				//   visible: true
				// },
				{
					key: "cintillo_patas",
					label: "CP",
					class: "text-center ",
					visible: this.categoryId == 1 || this.categoryId == 2 ? true : false,
					thClass: "px-50",
					tdClass: "px-50",
				},
				{
					key: "chicks_machos",
					label: "M",
					class: "text-center ",
					visible: this.categoryId == 1 || this.categoryId == 2 ? true : false,
					thClass: "px-50",
					tdClass: "px-50",
				},
				{
					key: "chicks_hembras",
					label: "H",
					class: "text-center ",
					visible: this.categoryId == 1 || this.categoryId == 2 ? true : false,
					thClass: "px-50",
					tdClass: "px-50",
				},
				{
					key: "chicks_sa",
					label: "SA",
					class: "text-center ",
					visible: this.categoryId == 1 || this.categoryId == 2 ? true : false,
					thClass: "px-50",
					tdClass: "px-50",
				},
				{
					key: "chicks_total",
					label: "Total",
					class: "text-center ",
					visible: this.categoryId == 1 || this.categoryId == 2 ? true : false,
					thClass: "px-50",
					tdClass: "px-50",
				},
				{
					key: "chicks_global",
					label: "Global",
					class: "text-center ",
					visible: this.categoryId == 1 || this.categoryId == 2 ? true : false,
					thClass: "px-50",
					tdClass: "px-50",
				},
				{
					key: "quantity_posturas_padrillos",
					label: "Posturas",
					class: "text-center ",
					visible: this.categoryId == 1 ? true : false,
				},
				{
					key: "quantity_posturas_madrillas",
					label: "Posturas",
					class: "text-center ",
					visible: this.categoryId == 2 ? true : false,
				},
				{
					key: "fertilidad",
					label: "Fertilidad",
					class: "text-center ",
					visible: this.categoryId == 1 || this.categoryId == 2 ? true : false,
				},
				{
					key: "madrilla",
					label: "Madrilla",
					class: "text-center ",
					visible: this.categoryId != 1 && this.categoryId != 2 ? true : false,
				},
				{
					key: "padrillo",
					label: "Padrillo",
					class: "text-center ",
					visible: this.categoryId != 1 && this.categoryId != 2 ? true : false,
				},
				{
					key: "tournaments",
					label: "Torneos",
					class: "text-center ",
					visible: this.categoryId == 3 ? true : false,
				},
				// {
				//   key: "s_gender",
				//   label: "Género",
				//   class: "text-center ",
				//   //sortable: true,
				//   visible: true
				// },
				{
					key: "origin",
					label: "Origen",
					class: "text-center ",
					visible: this.categoryId != 1 && this.categoryId != 2 ? true : false,
				},
				{
					key: "status",
					label: "Estado",
					class: "text-center ",
					//sortable: true,
					visible: true,
				},
				{
					key: "dob",
					label: "Edad",
					class: "text-center ",
					//sortable: true,
					visible: true,
				},
				// {
				//   key: "created_at_date",
				//   label: "Ingresado por",
				//   class: "text-center ",
				//   //sortable: true,
				//   visible: true,
				// },
				{
					key: "tracking_weight",
					label: "Peso (Kg)",
					class: "text-center",
					visible: true,
				},

				{
					key: "web",
					label: "Publico",
					class: "text-center ",
					visible: true,
				},
				{
					key: "venta",
					label: "Venta",
					class: "text-center ",
					visible: true,
				},
				{
					key: "actions",
					label: "Acciones",
					class: "text-center ",
					visible: true,
				},
			],
			data_sessions: [],
			modal_sessions: false,
			data: [],
			edit: [],
			list_users: true,
			create_update: false,
			disable: {
				save: false,
			},
			search_input: "",
			orderby: this.dato2 == null ? 3 : this.dato2,
			order: this.dato1 == null ? "desc" : this.dato1,
			start_page: "",
			end_page: "",
			total_data: "",
			perpage: 100,
			next_page: "",
			current_page: 1,
			to_page: "",
			dato1: "",
			dato2: "",
			loadingData: false,
			isBusy: false,
			filters: [
				{
					label: "ORIGEN",
					options: [],
					model: null,
					primaryKey: "id",
					labelSelect: "description",
				},
				{
					label: "COLOR",
					options: [],
					model: null,
					primaryKey: "id",
					labelSelect: "color",
				},
				{
					label: "ESTADO",
					options: [],
					model: null,
					primaryKey: "id",
					labelSelect: "description",
				},
				{
					label: "CRIADOR",
					options: [],
					model: null,
					primaryKey: "id",
					labelSelect: "name",
				},
			],
			filterController: false,
			modal_image: false,
			index: null,
			images: [],
			selectedSpecimen: {},
			arrayStatus: [],
			modalViewVideosBySpecimen: false,
			modalViewVideosBySpecimenItem: null,
			modalTotalPosturas: {
				title: "",
				content: "",
				show: false,
				idSpecimen: null,
			},
			modalTotalTournaments: {
				title: "",
				content: "",
				show: false,
				idSpecimen: null,
				plate: null,
			},
			modalSonsBySpecimen: {
				title: "",
				show: false,
				idSpecimen: null,
				plate: null,
				index: 1,
			},
			total: {
				total_machos: 0,
				total_hembras: 0,
				total_sa: 0,
				total_total: 0,
			},
			dataSpecimens: [],
			year: null,
			isLoading: true,
			currentSpecimen: {},
			controllerSideBarDeads: false,
			counterParent: 0,
			controllerSidebarChicksGlobal: false,
			specimenGlobal: {},
			modalAddDob: {
				show: false,
				title: null,
				dob: null,
			},
			modalToSale: {
				show: false,
				title: null,
			},
			counterPlatesPending: 0,
			showModalProcessChickPlates: false,
			counterWeb: 0,
			counterPublicAndSale: {},
			clinicalActions: { show: false },
			addNursery: { show: false, info: {} },
		}
	},
	methods: {
		...mapMutations("video", [
			"M_SET_INPUT",
			"M_RESET_FORM_VIDEO",
			"M_DATA_FILE",
			"M_SET_TOURNAMENT_SPECIMEN_ARRAY",
		]),
		onChangeFilter() {
			// this.$refs.refUserListTable.refresh();
		},
		openAddSpecimenWeightModal(specimen) {
			this.selectedSpecimen = specimen
			this.specimenWeightController = true
		},
		openSpecimenWeightListModal(specimen) {
			this.selectedSpecimen = specimen
			this.specimenWeightListController = true
		},
		async openSpecimenStatusChanged(specimen) {
			if (this.arrayStatus.length == 0) {
				await this.getSpecimenStatusTrackings()
			}
			this.selectedSpecimen = specimen
			this.specimenStatusController = true
		},
		async getSpecimenStatusTrackings() {
			this.arrayStatus = await specimenService.getAllStatusSpecimens()
			this.arrayStatus = this.arrayStatus.filter((item) => item.id != 3)
		},
		updateSpecimenWeight(specimen) {
			specimen.weight_counter = Number(specimen.weight_counter) + 1
			this.resetSearch()
		},
		async myProvider(ctx) {
			if (this.search_input.length > 0) this.$emit("clearSearchGlobal")
			const finalUrl = [1, 2].includes(this.categoryId) ? ctx.apiUrl : "api/specimens/by-category"
			const { data } = await axios.get(`${finalUrl}?page=${ctx.currentPage}`, {
				params: {
					orderby: ctx.sortDesc ? "asc" : "desc",
					order: ctx.sortBy ?? "created_at",
					campo:
						this.globalSearch.length > 0 && this.search_input.length == ""
							? this.globalSearch
							: this.search_input,
					perpage: ctx.perPage,
					filter_origin: this.filters[0].model,
					filter_color: this.filters[1].model,
					filter_category: this.categoryId,
					filter_status: this.filters[2].model,
					filter_rival: this.filters[3].model,
					date_start: this.year == null ? this.seasons[0].start : this.ammendedYear.start,
					date_end: this.year == null ? this.seasons[0].end : this.ammendedYear.end,
				},
			})
			const items = data.data
			this.dataSpecimens = data.data
			items.forEach((item) => {
				if (item.photo) {
					this.images.push({
						...item,
						title: item.alias + "  |  " + item.plate,
						src: item.photo,
					})
				}
			})
			this.data = data.data
			this.start_page = data.from
			this.current_page = data.current_page
			this.perpage = data.per_page
			this.next_page = this.start_page + 1
			this.end_page = data.last_page
			this.total_data = data.total
			this.to_page = data.to
			return items || []
		},
		resetSearch() {
			this.search_input = ""
			// this.$refs.refUserListTable.refresh();
			this.images = []
		},
		closeModals() {
			this.modal_sessions = false
		},
		formatPercent(e) {
			return [null, undefined].includes(e) ? "---" : `${e}%`
		},
		getcolorspecimen() {
			axios
				.get("/api/specimens/get-colors", {
					params: {
						gender_specimen: null,
						page: 1,
						perpage: 1000,
						search: null,
					},
				})
				.then((response) => {
					this.filters[1].options = response.data.colores.data
				})
				.catch((err) => {
					console.log(err)
				})
		},
		getdataselect() {
			this.getcolorspecimen()
			axios
				.get("/api/specimens/get-data-select-specimens")
				.then((response) => {
					if (response.status == 200) {
						this.filters[0].options = JSON.parse(response.data[0].data_origin)
						this.filters[3].options = JSON.parse(response.data[0].rivals)
						this.filters[2].options = JSON.parse(response.data[0].data_statuse)
					}
				})
				.catch((err) => {
					console.log(err)
				})
		},
		openmodal(id) {
			axios
				.post("/api/get-sessions-user", {
					iduser: id,
				})
				.then((response) => {
					if (response.data) {
						this.data_sessions = response.data
						this.modal_sessions = true
					}
				})
				.catch((err) => {
					console.log(err)
				})
		},
		showToast(variant, position, title, icon, text) {
			this.$toast(
				{
					component: ToastificationContent,
					props: {
						title,
						icon,
						text,
						variant,
					},
				},
				{
					position,
				}
			)
		},
		delete_specimen(id) {
			this.$swal({
				title: "Estas seguro?",
				text: "¡No podrás revertir esto!",
				icon: "warning",
				showCancelButton: true,
				confirmButtonColor: "#3490dc",
				cancelButtonColor: "#e3342f",
				confirmButtonText: "Confirmar",
				customClass: {
					cancelButton: "btn btn-outline-danger ml-1",
					confirmButton: "btn btn-primary",
				},
				buttonsStyling: false,
			}).then((result) => {
				if (result.value) {
					axios
						.delete(`/api/specimens/${id}`)
						.then((response) => {
							if (response.status == 200) {
								this.showToast(
									"success",
									"top-right",
									"Felicidades",
									"CheckIcon",
									"El ejemplar ha sido eliminado!"
								)
								// this.$refs.refUserListTable.refresh();
							}
						})
						.catch((err) => {
							console.log(err)
						})
				}
			})
		},
		viewSpecimen(item) {
			//get index of images where item id is equals images id
			let index = this.images.findIndex((x) => x.id == item.id)
			this.index = index
		},
		openInfo(item) {
			this.$router.push({
				name: "detalle-ejemplar",
				params: { specimen_id: item.id },
			})
		},
		setViewVideoBySpecimen(specimen) {
			this.modalViewVideosBySpecimen = true
			this.modalViewVideosBySpecimenItem = specimen
		},
		clickNumberPostura(item) {
			this.modalTotalPosturas.show = !this.modalTotalPosturas.show
			this.modalTotalPosturas.idSpecimen = item.id
			this.currentSpecimen = item
			this.modalTotalPosturas.title = `Total de posturas del ejemplar ${item.alias} ${item.plate ? "-" : ""} ${
				item.plate ? item.plate : ""
			}`
		},
		clickTournamentsBySpecimen(id, item) {
			this.modalTotalTournaments.show = !this.modalTotalTournaments.show
			this.modalTotalTournaments.idSpecimen = id
			this.modalTotalTournaments.plate = item.plate
			this.modalTotalTournaments.title = `${item.alias ? item.alias : ""} ${item.alias ? "-" : ""} (${
				item.plate ? item.plate : ""
			})`
		},
		clickSonsBySpecimenv2(item, tabIndex) {
			this.modalSonsBySpecimen.title = `Hijos del ejemplar ${item.alias ? item.alias : ""} ${
				item.alias ? "-" : ""
			} (${item.plate ? item.plate : ""})`
			this.modalSonsBySpecimen.show = !this.modalSonsBySpecimen.show
			this.modalSonsBySpecimen.idSpecimen = item.id
			this.modalSonsBySpecimen.index = tabIndex
		},
		async closeSidebarMassivelyChanges() {
			this.sidebarMassivelyChanges = false
		},
		openParentsDead() {
			this.controllerSideBarDeads = true
		},
		openSidebarChicksGlobal(data) {
			this.specimenGlobal = data
			this.controllerSidebarChicksGlobal = true
		},
		sumGlobal(dataSpecimens) {
			return dataSpecimens.reduce((a, b) => {
				return (
					a +
					parseInt(isNaN(b.cd_global * 1) ? 0 : b.cd_global * 1) +
					parseInt(isNaN(b.cp_global * 1) ? 0 : b.cp_global * 1)
				)
			}, 0)
		},
		addDobSpecimen(specimen) {
			console.log("specimen", specimen)
			this.modalAddDob.show = true
			this.modalAddDob.title = `Agregar nacimiento ${specimen.alias ? specimen.alias : ""} (${specimen.plate})`
			this.modalAddDob.specimen_id = specimen.id
			// this.modalAddDob.specimen_id = 12
		},
		async saveDobSpecimen() {
			if (!(await this.$refs.form.validate())) {
				this.showToast(
					"danger",
					"top-right",
					"Campos Incompletos",
					"AlertCircleIcon",
					"Faltan campos por llenar"
				)
				return
			}

			let body = {
				specimen_id: this.modalAddDob.specimen_id,
				key: "dob",
				value_text: this.modalAddDob.dob,
			}

			this.isPreloading()

			const { message, status } = await specimenService.updateFieldSpecimen(body)

			if (status) {
				this.showToast("success", "top-right", "Corrales", "SuccessIcon", message)

				// const indexSpecimen = this.spe
				// this.$refs.refUserListTable.refresh();

				this.modalAddDob = {
					show: false,
					title: null,
					dob: null,
					specimen_id: null,
				}
			}

			this.isPreloading(false)
		},
		async emplacedCounter() {
			let { data } = await CorralService.getCountPlacationRequests()
			this.counterPlatesPending = data.counter
		},
		openModalEmplacados() {
			if (this.counterPlatesPending == 0) {
				return this.showToast("warning", "top-right", "Emplacados", "WarningIcon", "Sin pollos emplacados.")
			}

			if (this.counterPlatesPending > 0) {
				this.showModalProcessChickPlates = !this.showModalProcessChickPlates
			}
		},
		async webCounter() {
			let { data } = await publicWebService.getGeneralCounter()
			// console.log("././.", data);
			this.counterWeb = data.reduce((prev, category) => prev + category.counter, 0)
			this.counterPublicAndSale = data
		},
		refreshMainGrid() {
			bus.$emit("refresh-specimens-table")
		},
	},
	created() {
		this.getdataselect()
		this.emplacedCounter()
		this.webCounter()
		bus.$on("reload-counter-web", async () => {
			await this.webCounter()
		})
	},

	async mounted() {},
	destroyed() {
		this.M_RESET_FORM_VIDEO({ category_idIn: 1, type_idIn: 1 })
	},
	setup() {
		const USER_APP_STORE_MODULE_NAME = "app-user"

		// Register module
		if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
			store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

		// UnRegister on leave
		onUnmounted(() => {
			if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
		})

		const isAddNewUserSidebarActive = ref(false)

		const roleOptions = [
			{ label: "Admin", value: "admin" },
			{ label: "Author", value: "author" },
			{ label: "Editor", value: "editor" },
			{ label: "Maintainer", value: "maintainer" },
			{ label: "Subscriber", value: "subscriber" },
		]

		const planOptions = [
			{ label: "Basic", value: "basic" },
			{ label: "Company", value: "company" },
			{ label: "Enterprise", value: "enterprise" },
			{ label: "Team", value: "team" },
		]

		const statusOptions = [
			{ label: "Pending", value: "pending" },
			{ label: "Active", value: "active" },
			{ label: "Inactive", value: "inactive" },
		]

		const {
			fetchUsers,
			tableColumns,
			perPage,
			currentPage,
			totalUsers,
			dataMeta,
			perPageOptions,
			searchQuery,
			sortBy,
			isSortDirDesc,
			refUserListTable,
			refetchData,

			// UI
			resolveUserRoleVariant,
			resolveUserRoleIcon,
			resolveUserStatusVariant,

			// Extra Filters
			roleFilter,
			planFilter,
			statusFilter,
		} = useUsersList()

		return {
			// Sidebar
			isAddNewUserSidebarActive,

			fetchUsers,
			tableColumns,
			perPage,
			currentPage,
			totalUsers,
			dataMeta,
			perPageOptions,
			searchQuery,
			sortBy,
			isSortDirDesc,
			refUserListTable,
			refetchData,

			// Filter
			avatarText,

			// UI
			resolveUserRoleVariant,
			resolveUserRoleIcon,
			resolveUserStatusVariant,

			roleOptions,
			planOptions,
			statusOptions,

			// Extra Filters
			roleFilter,
			planFilter,
			statusFilter,
		}
	},
}
</script>

<style lang="scss" scoped>
.per-page-selector {
	width: 90px;
}
td.div {
	width: 100% !important;
}
@media (max-width: 960px) {
	.column-table {
		display: flex;
		flex-direction: column;
	}
}
</style>

<style lang="scss">
.muda-gray {
	filter: grayscale(2) !important;
}

.media-body {
	position: relative;
	.container-ran {
		position: absolute;
		top: 50%;
		right: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		transform: TRANSLATEY(-50%);
		img {
			width: 32px;
			height: 32px;
			object-fit: cover;
			@media screen and (min-width: 2000px) {
				width: 40px;
				height: 40px;
			}
		}
	}
}
.container-plate-specimen {
	margin-right: 1rem;
}
.container-btn-update-status {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}
.container-btns-page-specimens {
	// background: red;
	display: flex;
	flex-direction: column;
	@media screen and (min-width: 900px) {
		flex-direction: row;
		justify-content: space-between;
	}
	@media screen and (min-width: 1500px) {
		flex-direction: row;
		justify-content: flex-end;
	}
	.btns {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		flex-basis: auto;
		@media screen and (min-width: 600px) {
			flex-direction: row;
		}
		.btn {
			width: 100%;
			min-width: 180px;
			margin-top: 1rem;
			@media screen and (min-width: 900px) {
				margin-top: 0;
			}
		}
	}
}
.sons-specimens-v2 {
	p {
		margin-bottom: 0;
	}
}
.sons-specimens-v3 {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
}
</style>
